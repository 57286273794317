import { api } from '@/api/client';
import { ModalType, TargetUser } from '@/components/AuthModal.vue';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { useAuth0 } from '@auth0/auth0-vue';
import { SecondaryAccountType } from '@careos/organization-api-types';
import * as Sentry from '@sentry/vue';
import { AxiosError } from 'axios';
import { ref, watch } from 'vue';

export function useAccountLinking() {
  const ACCOUNT_LINKING_CLIENT_ID = import.meta.env
    .VITE_ACCOUNT_LINKING_CLIENT_ID;
  const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
  const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
  const BANKID_CONNECTION_NAME = import.meta.env.VITE_BANKID_CONNECTION_NAME;
  const SITHS_CONNECTION_NAME = import.meta.env.VITE_SITHS_CONNECTION_NAME;
  if (
    !ACCOUNT_LINKING_CLIENT_ID ||
    !AUTH0_DOMAIN ||
    !BANKID_CONNECTION_NAME ||
    !AUTH0_AUDIENCE ||
    !SITHS_CONNECTION_NAME
  ) {
    throw new Error('Missing environment variables for account linking.');
  }
  const NOT_PART_REGEX = /user (.*?) is not part of the (.*?) organization/;

  const isLinkingLoading = ref(false);
  const { user, loginWithRedirect, idTokenClaims, logout, error } = useAuth0();
  const isBankidLinked = ref(true);
  const isSithsLinked = ref(true);
  const isPopUpClosed = ref(false);
  const authModal = ref<{
    data?: Partial<TargetUser>;
    type: ModalType;
    accountType: SecondaryAccountType;
  }>({ type: 'none', accountType: SecondaryAccountType.BankID });

  const a0 = new Auth0Client({
    // INFO: This client needs to be to an intermediary auth0 application, that requires no organization
    // and has our third party IDP connections enabled (BankID & SITHS). It also needs to have the correct callback URL:s for
    // the target application, so that the user can be redirected back to the correct page.
    clientId: ACCOUNT_LINKING_CLIENT_ID,
    domain: AUTH0_DOMAIN,
    authorizationParams: {
      audience: AUTH0_AUDIENCE,
    },
    useCookiesForTransactions: true,
  });

  const relog = async (target?: { organizationId: string }) => {
    await logout();
    await loginWithRedirect({
      authorizationParams: {
        organization: target?.organizationId,
        max_age: 0,
      },
    });
  };

  const authenticate = async (connection: string): Promise<string> => {
    await a0.loginWithPopup({
      authorizationParams: {
        connection,
        max_age: 0,
      },
    });
    const newUser = await a0.getUser();
    const newUserId = newUser?.sub;
    if (!newUserId) {
      throw new Error('No user account ID from logged in account.');
    }

    return newUserId;
  };

  const getSecondaryAccountId = async (
    accountType: SecondaryAccountType,
  ): Promise<{
    secondaryAccountId: string;
  }> => {
    // INFO: The primary ID should always be the email user, and
    // the secondary ID should always be the secondary account user.
    const connection = getConnectionNameByAccountType(accountType);
    const secondaryAccountId = await authenticate(connection);
    return {
      secondaryAccountId,
    };
  };

  const getConnectionNameByAccountType = (
    accountType: SecondaryAccountType,
  ): string => {
    switch (accountType) {
      case SecondaryAccountType.BankID: {
        return BANKID_CONNECTION_NAME;
      }
      case SecondaryAccountType.SITHS: {
        return SITHS_CONNECTION_NAME;
      }
      default: {
        throw new Error('Invalid account type');
      }
    }
  };

  const handleError = (e: unknown, source: 'link' | 'auth') => {
    // INFO: The AxiosErrors are from the backend. And we ourselves
    // determine the error messages.
    if (e instanceof AxiosError) {
      if (e.response?.data.message.includes('user not found')) {
        authModal.value.type = 'user_not_found';
        return;
      }
      if (e.response?.data.message.includes('user update failed')) {
        authModal.value.type = 'update_failed';
        return;
      }
    }
    if (e instanceof Error && 'message' in e) {
      // INFO: If the user closes the pop-up manually, we don't want to
      // annoy them with another pop-up. It is a clear action from their side.
      // The login required and multifactor auth required can come when their
      // session has expired, and that should not trigger a modal, they will
      // get kicked out anyway.
      if (
        e.message === 'Login required' ||
        e.message === 'Multifactor authentication required' ||
        e.message === 'Popup closed'
      ) {
        authModal.value = {
          type: 'none',
          accountType: authModal.value.accountType,
        };
        isPopUpClosed.value = true;
        return;
      }
      if (e.message === 'Timeout') {
        authModal.value = {
          type: 'timeout',
          accountType: authModal.value.accountType,
        };
        return;
      }
      // INFO: UserCancel is when the user has pressed cancel after starting
      // the signing process in their IdP provider end.
      if (e.message.includes('userCancel')) {
        if (source === 'auth') {
          authModal.value = {
            type: 'user_cancel_login',
            accountType: authModal.value.accountType,
          };
          return;
        }
        authModal.value = {
          type: 'user_cancel_link',
          accountType: authModal.value.accountType,
        };
        return;
      }
      // INFO: Collect failed is a BankID error, and can happen for many reasons,
      // most likely some kind of downtime of the BankID API.
      if (e.message.includes('Collect failed')) {
        authModal.value.type = 'linking_error';
        return;
      }
      // INFO: This can happen if the user has already linked their account and
      // tries again.
      if (
        e.message.includes('user update failed') ||
        e.message === 'link same user'
      ) {
        authModal.value.type = 'update_failed';
        return;
      }
      if (e.message === 'link has expired') {
        authModal.value.type = 'registration_link_expired';
        return;
      }
      if (e.message === 'link same provider') {
        authModal.value.type = 'linking_secondary_account_not_found';
        return;
      }
      // INFO: This is telling us that the user is not a part of the organization
      // they are trying to authenticate towards. This can mean two things, either
      // they have just typed in the wrong organization, or they are trying to
      // authenticate with BankID before any link has been setup to their
      // account that actually belongs to the organization that they want to
      // login to.
      // Example of messages:
      // user auth0|67b5a94b09592893f7e18c37 is not part of the org_6pSLB6r6UfYDKKyh organization
      // user oidc|criipto-dev|{75c231da-721c-42c0-a80e-edc0c5d238e4} is not part of the org_zMTOZ38T32zX6rQK organization
      if (e.message.match(NOT_PART_REGEX)) {
        const match = e.message.match(NOT_PART_REGEX);
        const connectionName = match![1].split('|')[0];
        const isSecondaryAccount = !connectionName.startsWith('auth0');

        authModal.value = {
          type: isSecondaryAccount
            ? 'unlinked_secondary_account'
            : 'not_part_of_org',
          accountType: authModal.value.accountType,
        };
        return;
      }
    }
    // INFO: If we don't know what the error is, it can be good to capture it
    // and log it to sentry, so that we can improve the feedback to our users.
    Sentry.captureException(e);
    if (source === 'link') {
      authModal.value.type = 'linking_error';
    } else {
      authModal.value = {
        type: 'unknown',
        accountType: authModal.value.accountType,
      };
    }
  };

  const getOrganizationIdFromCurrentUser = (isEmailLink: boolean) => {
    // INFO: In the rare case that the one receiving the registration
    // email link would be on the same machine as the admin that initiated
    // this registration request, it can cause an issue where the access
    // token is fetched from the cookies, and from this token we parse out
    // the organization, which may or may not be the same as the user
    // wants to login with. Hence, knowing that we we're coming from
    // an email when linking, we should ignore the organization. There's
    // no valid case where we can know the orgazation to log them into.
    if (isEmailLink) return undefined;
    return idTokenClaims.value?.org_id;
  };

  // INFO:
  // This links a secondary account to the primary account.
  // The primary account is the one that is logged in, or defined in encryptedPrimaryAccountToken if it's an email link.
  // The user must always authenticate himself with the secondary account, where we pick the user id.
  const linkAccounts = async (
    secondaryAccountType: SecondaryAccountType,
    encryptedPrimaryAccountToken?: string,
  ) => {
    try {
      isLinkingLoading.value = true;
      authModal.value.accountType = secondaryAccountType;

      const { secondaryAccountId } =
        await getSecondaryAccountId(secondaryAccountType);
      await callApi({ secondaryAccountId, encryptedPrimaryAccountToken });

      const isEmailLink = Boolean(encryptedPrimaryAccountToken);
      const organizationId = getOrganizationIdFromCurrentUser(isEmailLink);
      authModal.value = {
        data: {
          organizationId,
        },
        type: 'linking_success',
        accountType: secondaryAccountType,
      };
    } catch (e) {
      handleError(e, 'link');
    } finally {
      isLinkingLoading.value = false;
    }
  };

  const callApi = async ({
    secondaryAccountId,
    encryptedPrimaryAccountToken,
  }: {
    secondaryAccountId: string;
    encryptedPrimaryAccountToken?: string;
  }) => {
    const response = encryptedPrimaryAccountToken
      ? await api.linkAccountsWithToken({
          secondaryAccountId,
          encryptedPrimaryAccountToken,
        })
      : await api.linkAccounts({ secondaryAccountId });

    if (response !== 'successful linking') {
      // TODO: Remove after finding bug.
      if (response !== 'link has expired') {
        Sentry.captureMessage(
          // INFO: Apparently, the data can be an object. The standard success-object is
          // a string, and it's supposed to be the return type for unsuccessful linkings
          // as well, but I'm guessing that's not the case when we get errors thrown.
          `A problem occured while linking accounts: ${JSON.stringify(response)}`,
          {
            level: 'log',
            tags: {
              encryptedEmailUser: encryptedPrimaryAccountToken,
              primaryAccountId: user.value?.sub,
              secondaryAccountId: secondaryAccountId,
            },
          },
        );
      }
      throw new Error(response);
    }
  };

  watch(error, (newError) => {
    handleError(newError, 'auth');
  });

  watch(user, async (newUser) => {
    if (!newUser?.connections) return;
    const bankidConnection = newUser.connections.find(
      (it: string) => it === BANKID_CONNECTION_NAME,
    );
    const sithsConnection = newUser.connections.find(
      (it: string) => it === SITHS_CONNECTION_NAME,
    );
    isBankidLinked.value = Boolean(bankidConnection);
    isSithsLinked.value = Boolean(sithsConnection);
  });

  return {
    isLinkingLoading,
    isBankidLinked,
    authModal,
    linkAccounts,
    relog,
    isPopUpClosed,
    isSithsLinked,
  };
}
