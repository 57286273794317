<template>
  <ul>
    <li>
      {{ t('admin_board.user_details.name') }}: <b>{{ userDetails.name }}</b>
    </li>
    <li>
      {{ t('admin_board.user_details.email') }}: <b>{{ userDetails.email }}</b>
    </li>
    <li>
      {{ t('admin_board.user_details.role') }}:
      <b>
        {{ roleLabel ? t(labelToLongTranslationKey(roleLabel)) : '' }}
      </b>
    </li>
    <li v-if="showEmployerScope">
      {{ t('admin_board.user_details.employer_scope') }}:
      <b>
        {{
          userDetails.employerScope?.name ||
          t('admin_board.user_details.employer_scope_unlimited')
        }}
      </b>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { labelToLongTranslationKey } from '@/utils/user-role';
import { UserFromResponseDto } from '@/views/admin-view/stores/users';
import { computed } from 'vue';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps<{
  userDetails: UserFromResponseDto;
  showEmployerScope: boolean;
}>();

const roleLabel = computed(() => {
  return props.userDetails.roles.at(0)?.name;
});
</script>
