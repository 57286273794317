<template>
  <div class="flex h-full flex-col items-center justify-center">
    <SvgIcon class="m-6 h-36 rounded-md p-4" name="abclabs" />
  </div>
  <AuthModal
    v-if="authModal.type !== 'none'"
    :modal-context="authModal"
    :loading="isLinkingLoading"
    @link="handleLink"
    @relog="relog"
    @retry="handleLink"
    @cancel="logout"
    @abort="authModal = { type: 'none', accountType: authModal.accountType }"
  />
</template>
<script setup lang="ts">
import { onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { router } from '@/router';
import { useAccountLinking } from '../../composables/useAccountLinking';
import SvgIcon from '../../components/SvgIcon.vue';
import { useAuth0 } from '@auth0/auth0-vue';
import AuthModal from '../../components/AuthModal.vue';
import { SecondaryAccountType } from '@careos/organization-api-types';

/**
 * INFO: This view is used when people click the BankID / SITHS link from email containing the encrypted user data.
 */
const { logout } = useAuth0();
const { linkAccounts, isLinkingLoading, authModal, relog, isPopUpClosed } =
  useAccountLinking();

const handleLink = async () => {
  await linkAccounts(authModal.value.accountType, encryptedUserData);
};

const route = useRoute();
const accountType = (route.params['accountType'] ||
  SecondaryAccountType.BankID) as SecondaryAccountType;
const encryptedUserData = Array.isArray(route.params['encryptedUserData'])
  ? route.params['encryptedUserData'].at(0)
  : route.params['encryptedUserData'];

onMounted(async () => {
  if (!encryptedUserData) {
    return;
  }
  authModal.value.type = 'register_with_secondary_account';
  authModal.value.accountType = accountType;
});

watch(isPopUpClosed, (isClosed) => {
  if (isClosed) {
    isPopUpClosed.value = false;
    router.push('/');
  }
});
</script>
