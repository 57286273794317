<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <button
      class="rounded-md border px-4 py-2"
      type="button"
      @click="toggleMenu"
    >
      <span class="mr-2">{{ user.name }}</span>
      <PrimeIcon size="2XS" :icon="'CHEVRON_DOWN'" />
    </button>
    <TieredMenu ref="menu" :model="items" :popup="true">
      <template #item="{ item, props, hasSubmenu }">
        <div class="align-items-center flex" v-bind="props.action">
          <SvgIcon
            v-if="item.icon === 'BANKID_ICON'"
            class="ml-[-8px] mr-[-6px] h-6"
            name="BankID_logo_black"
          />
          <SvgIcon v-if="item.icon === 'SITHS'" name="siths" class="h-6 py-1" />
          <span v-else :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
          <i v-if="hasSubmenu" class="pi pi-angle-right ml-auto"></i>
        </div>
      </template>
    </TieredMenu>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import TieredMenu from 'primevue/tieredmenu';
import type { User } from '@auth0/auth0-vue';
import { PrimeIcon } from 'careos-vue-components';
import type { MenuItem } from 'primevue/menuitem';
import { useLocaleStorage } from '@/composables/useLocaleStorage';
import SvgIcon from '../SvgIcon.vue';

const { t, locale } = useI18n();
const { updateLocale } = useLocaleStorage();

const emits = defineEmits<{
  logout: [returnTo: string];
  'activate-bankid': [];
  'activate-siths': [];
}>();

const props = defineProps<{
  user: User;
  showActivateBankid: boolean;
  showActivateSiths: boolean;
}>();

const menu = ref();
const items = computed<MenuItem[]>(() => [
  {
    label: t('general.language'),
    icon: 'pi pi-language',
    items: [
      {
        icon:
          locale.value === 'sv-SE'
            ? 'pi pi-check'
            : 'pi pi-check text-transparent',
        command: () => {
          updateLocale('sv-SE');
        },
        label: t('general.swedish'),
      },
      {
        label: t('general.english'),
        icon:
          locale.value === 'en-US'
            ? 'pi pi-check'
            : 'pi pi-check text-transparent',
        command: () => {
          updateLocale('en-US');
        },
      },
    ],
  },
  ...(props.showActivateBankid
    ? [
        {
          label: t('authentication.activate_bankid'),
          icon: 'BANKID_ICON',
          command: () => emits('activate-bankid'),
        },
      ]
    : []),
  ...(props.showActivateSiths
    ? [
        {
          label: t('authentication.activate_siths'),
          icon: 'SITHS',
          command: () => emits('activate-siths'),
        },
      ]
    : []),
  {
    label: t('general.log_out'),
    icon: 'pi pi-sign-out',
    command: () => emits('logout', window.location.origin),
  },
  {
    separator: true,
  },
  {
    label: props.user.email,
    icon: 'pi pi-envelope',

    disabled: true,
  },
]);

const toggleMenu = (event: Event) => {
  menu.value.toggle(event);
};
</script>
