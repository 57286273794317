<template>
  <Toast position="top-right" />
  <AuthModal
    v-if="authModal.type !== 'none'"
    :modal-context="authModal"
    :loading="isLinkingLoading"
    @link="linkAccounts(authModal.accountType)"
    @relog="relog"
    @retry="linkAccounts(authModal.accountType)"
    @cancel="logout"
    @abort="authModal = { type: 'none', accountType: authModal.accountType }"
  />
  <div id="main">
    <div v-if="isAuthenticated" class="header fixed z-10 h-16 w-[100vw]">
      <CareosHeader
        :permissions="authStore.permissions"
        :show-activate-bankid="!isBankidLinked"
        :show-activate-siths="!isSithsLinked"
        @activate-bankid="linkAccounts(SecondaryAccountType.BankID)"
        @activate-siths="linkAccounts(SecondaryAccountType.SITHS)"
      />
    </div>
    <div :class="['body overflow-scroll', { 'mt-16': isAuthenticated }]">
      <RouterView />
    </div>
    <CareosFooter v-if="isAuthenticated" />
  </div>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import AuthModal from './components/AuthModal.vue';
import Toast from 'primevue/toast';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import CareosHeader from './components/CareosHeader/CareosHeader.vue';
import CareosFooter from './components/CareosFooter.vue';
import { useFormkitConfig } from './formkit/use-formkit-config';
import { useAuthStore } from './stores/auth';
import { useAccountLinking } from './composables/useAccountLinking';
import { useLocaleStorage } from './composables/useLocaleStorage';
import { SecondaryAccountType } from '@careos/organization-api-types';

const { locale } = useI18n();
const { i18nStorage } = useLocaleStorage();
const formkit = useFormkitConfig();
const { isAuthenticated, logout } = useAuth0();
const authStore = useAuthStore();
const {
  isLinkingLoading,
  isBankidLinked,
  authModal,
  linkAccounts,
  relog,
  isSithsLinked,
} = useAccountLinking();

watch(
  () => i18nStorage.value.locale,
  (newLocale) => {
    locale.value = newLocale;
  },
  { immediate: true },
);

watch(
  locale,
  (locale) => {
    formkit.locale = locale;
  },
  { immediate: true },
);
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.p-dialog {
  .p-confirm-dialog-accept {
    @apply bg-careos-purple text-white hover:bg-careos-lavendel active:bg-careos-purple active:text-careos-lavendel;
  }
  .p-confirm-dialog-reject {
    @apply text-careos-purple hover:text-careos-purple active:text-careos-lavendel;
  }
}
.p-dropdown {
  .p-placeholder {
    @apply px-3 text-left align-middle;
  }

  &:focus-within {
    @apply border-careos-lavendel #{!important};
  }

  &:not(.p-disabled):hover {
    border-color: #ced4da;
  }
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.3s ease-out;
}

.slide-left-leave-to,
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-50%);
}

.slide-left-enter-from,
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(50%);
}

:focus-within {
  outline: 0;
}

#main {
  display: grid;
  grid-template: 'body' 1fr;
  min-height: 100vh;
}

.body {
  grid-area: body;
  background-image: url('./assets/background.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.footer {
  position: relative;
}

::-webkit-scrollbar {
  display: none;
}
</style>
