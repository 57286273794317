<template>
  <div>
    <FormKit ref="formGroup" v-model="data" type="group">
      <FormKit
        name="barcode"
        :label="t('barcode.manual_input.input_label')"
        type="text"
        validation="required"
        :plugins="[forceUppercase]"
      />
      <FormKit
        name="barcode_confirm"
        :label="t('barcode.manual_input.verification_label')"
        type="text"
        validation="required|confirm"
        :plugins="[forceUppercase]"
      />
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
import { FormKitNode } from '@formkit/core';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const formGroup = ref<{ node: FormKitNode } | null>(null);

const forceUppercase = (node: FormKitNode) => {
  node.hook.input((value, next) => next(value.toUpperCase()));
};

const props = defineProps<{
  barcode?: string;
}>();

const data = ref({
  barcode: props.barcode ?? '',
  barcode_confirm: props.barcode ?? '',
});

const emit = defineEmits(['updateBarcode']);

const isValid = computed(() => formGroup.value?.node?.context?.state?.valid);

watch(isValid, (newValue) => {
  emit('updateBarcode', newValue ? data.value.barcode : '');
});

onMounted(async () => {
  if (props.barcode) return;

  await new Promise((res) => setTimeout(res, 500));

  const barcodeInput: HTMLElement | null = document.querySelector(
    'input[name="barcode"]',
  );
  barcodeInput?.focus();
});
</script>
