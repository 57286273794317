<template>
  <OrderViewWrapper>
    <OrderViewCard
      data-test-id="treatment-center-selection"
      :forward-label="t('general.next_step')"
      :disable-forwards="
        !samplingLocationStore.formDataTc?.treatment_center?.value
      "
      :title="t('treatmentCenter.title')"
      @action-right="nextStep()"
      @action-left="previousStep()"
    >
      <div class="flex min-w-[250px] flex-col lg:flex-row">
        <div class="w-full">
          <FormKit v-model="samplingLocationStore.formDataTc" type="group">
            <FormKit
              ref="treatmentCenterDropdown"
              :placeholder="t('treatmentCenter.selectTreatmentCenter')"
              :label="t('treatmentCenter.treatmentCenter')"
              :custom-options="treatmentCenterOptions"
              :filter="true"
              type="dropdown"
              data-test-id="tc-dropdown"
              name="treatment_center"
              validation="required"
              option-label="label"
            />
          </FormKit>
        </div>
      </div>
    </OrderViewCard>
  </OrderViewWrapper>
</template>
<script setup lang="ts">
import { router } from '@/router';
import { useOrderViewStore } from '@/views/order-view/store/order-view-store';
import { useSamplingLocationStore } from '@/stores/order/samplingLocationStore';
import { FormKitNode } from '@formkit/core';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';
import OrderViewCard from '../../components/OrderViewCard.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import { useToast } from 'primevue/usetoast';
import { useSamplingLocation } from '@/composables/useSamplingLocation';

const store = useOrderViewStore();
const samplingLocationStore = useSamplingLocationStore();

const { t } = useI18n();
const toast = useToast();
const { fetchTreatmentCenters, treatmentCenterOptions } = useSamplingLocation();

const treatmentCenterDropdown = ref<{ node: FormKitNode } | null>(null);

const nextStep = () => {
  if (store.testToOrderTypes.options.length > 1) {
    router.push({
      path: '/order/test-type',
    });
  } else if (store.testToOrderTypes.options.length === 1) {
    store.testToOrderTypes.selectedTestToOrderType =
      store.testToOrderTypes.options[0];

    router.push({
      path: '/order/personal-details',
    });
  } else {
    toast.add({
      life: 10_000,
      severity: 'error',
      summary: t('general.no_available_test_types'),
    });
  }
};

const previousStep = () => {
  router.go(-1);
};

window.onbeforeunload = () => {
  if (treatmentCenterDropdown.value?.node.context?.state.dirty) {
    return true;
  }
};

fetchTreatmentCenters();

onBeforeRouteLeave((to, _, next) => {
  if (
    treatmentCenterDropdown.value?.node.context?.state.dirty &&
    !to.fullPath.includes('order')
  ) {
    const answer = window.confirm(t('system.leave_warning'));
    if (answer) {
      next();
    } else {
      next(false);
    }
  } else {
    next();
  }
});
</script>
@/stores/order/samplingLocationStore
